import * as React from "react";
import axios from "axios";
import styled from "@emotion/styled";
import queryString from "query-string";
import { Input } from "@fitplan/lib/components/Input";
import { useConfig } from "@fitplan/context/lib-es/config";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";
import { PageTitle } from "@fitplan/lib/components/Header/PageTitle";
import { BrandButton } from "@fitplan/lib/components/Button/BrandButton";
import { LoginForm, LoginFooter } from "@fitplan/lib/components/UserAuth/LoginForm";
import { PageSubTitle } from "@fitplan/lib/components/Header/PageSubTitle";
import { SignUpFooter, SignUpForm } from "@fitplan/lib/components/UserAuth/SignUpForm";

import PageWrapper from "../components/pageWrapper/PageWrapper";
import { LoginFormStrings } from "../components/strings/LoginFormStrings";
import { SignupFormStrings } from "../components/strings/SignupFormStrings";
import useMount from "react-use/lib/useMount";

export interface Props {}

const Login: React.SFC<Props> = (props) => {
    const { access_token } = useAuthContext();
    const [completed, setCompleted] = React.useState<boolean>(false);
    const [isLogin, setIsLogin] = React.useState<boolean>(true);
    const [codeIsSet, setCodeIsSet] = React.useState<boolean>(false);
    const [code, setCode] = React.useState<string>("");
    const [error, setError] = React.useState<string>("");
    const submit = React.useRef<HTMLButtonElement>();
    React.useEffect(() => {
        if (typeof window === "undefined") return;
        const query = queryString.parse(window.location.search);
        if (query && query.code) {
            const code = typeof query.code === "string" ? query.code : query.code[0];
            setCode(code);
            setCodeIsSet(true);
        }
    }, []);
    
    return (
        <PageWrapper {...props}>
            <PageTitle>Sign in to your TV</PageTitle>
            {completed ? <SubTitle>Success! Your TV should sign in shortly</SubTitle> : (
                !codeIsSet || error ? (
                    <form onSubmit={async (event) => {
                        event.preventDefault();
                        if (code.length < 6) {
                            setError("Code must be at least six characters long")
                        } else {
                            setCodeIsSet(true);
                        }
                    }}>
                        <Code type="string" value={code} placeholder="TV Login Code" onChange={event => {
                            setCode(event.target.value);
                        }} />
                        {error && <Error>{error}</Error>}
                        <Submit ref={submit} disabled={code.length < 6} type="submit">Submit</Submit>
                    </form>
                ) : (
                    isLogin ? (
                        <LoginFormStrings>
                            <LoginForm onComplete={() => {}} />
                            <LoginFooter onClick={() => setIsLogin(false)} />
                        </LoginFormStrings>
                    ) : (
                        <SignupFormStrings>
                            <SignUpForm onComplete={() => {}} />
                            <SignUpFooter onClick={() => setIsLogin(true)} />
                        </SignupFormStrings>
                    )
                )
            )}
            {access_token && codeIsSet && <Submitter code={code} setError={setError} setCompleted={setCompleted} />}
            <Polygon polygonColor="#111213" borderColor="#00ffb3" />
        </PageWrapper>
    );
};

const Submitter: React.FunctionComponent<{code: string, setError: (value: string) => void, setCompleted: (value: boolean) => void}> = ({code, setError, setCompleted}) => {
    const { apiBaseUrl } = useConfig();
    const { access_token } = useAuthContext();
    useMount(async () => {
        try {
            const result = await axios({
                method: "POST",
                url: `${apiBaseUrl}/v1/television/tizen/login?code=${code.toUpperCase()}`,
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            if (result.data.error) {
                setError(result.data.error);
            } else {
                setCompleted(true);
            }
        } catch (err) {
            setError(err.message);
        }
    });
    return <span />;
}

const SubTitle = styled(PageSubTitle)`
    margin-top: 24px;
`;

const Code = styled(Input)`
    flex: 0 0 auto;
    width: 100%;
    max-width: 400px;
    margin: auto;

    border-radius: 0;
`;

const Error = styled.p`
    color: #f10364;
    margin-top: 8px;

    text-align: center;
`;

const Submit = styled(BrandButton)`
    flex: 0 0 auto;

    width: 200px;
    line-height: 24px;
    margin: auto;
    margin-top: 16px;
`;

export default Login;
